import axios from 'axios';
import { useEffect, useState } from 'react';
import { ExternalLinkButton, LinkButton } from '../../components/button/button';
import { HOME_URL /*, RENE_PORTAL_URL */ } from '../../global/constants';
import { Link } from 'react-router-dom';
import { Pages } from '../../global/routes';
import Animate from '../../components/animate/animate';
import Slider from '../../components/slider/slider';
// import RoadMap from './road-map/road-map';
import ReneLogo from '../../components/rene-logo';
import HeadMeta from '../../components/head-meta';
import FeatureAds from './feature-ads/feature-ads';
import BannerSlider from './banner-slider/banner-slider';
import PartnersCard from './partners-card/partners-card';

import retroGame from './images/retro-game.png';
import poster from './images/bkg-poster.webp';
import Odometer from 'react-odometerjs';
import ancient8Logo from './images/ancient8-logo.png';

import './home-page.scss';
import './animations.scss';
import 'odometer/themes/odometer-theme-minimal.css';

export interface HomePageData {
  articles: [{ logo: string; link: string }];
  partners: { [key: string]: any }[];
  pilot: [{ logo: string; link: string }];
  'pilot-stats': { brands: string; games: string; monthly: string; reach: string };
}

const HomePage = () => {
  const [counter, setCounter] = useState(0);

  const [homePageData, setHomePageData] = useState<HomePageData>();
  const [extended, setExtended] = useState(false);

  useEffect(() => {
    axios.get<HomePageData>(HOME_URL).then((res) => {
      setHomePageData({
        ...res.data,
        partners: res.data.partners.map((p) => ({ ...p, extended, setExtended })),
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!homePageData) return;
    setHomePageData({
      ...homePageData,
      partners: homePageData.partners.map((p) => ({ ...p, extended, setExtended })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extended]);

  useEffect(() => {
    const fetchData = () =>
      axios
        .get<{ totalImpressions: number }>(`${process.env.REACT_APP_API}/total-impressions`)
        .then((res) => {
          setCounter(res.data?.totalImpressions);
        });
    fetchData();
    const interval = setInterval(fetchData, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => setCounter(counter + 10), 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [counter]);

  return (
    <div className="home-page">
      <HeadMeta title="Borderless Gaming" description="Reneverse" page="home" />
      <div className="home-page__landing">
        <img src={retroGame} alt="" />
        <div className="home-page__landing_buttons">
          <LinkButton to={Pages.ADVERTISERS} variant="line">
            I'm an Advertiser
          </LinkButton>
          <LinkButton to={Pages.PUBLISHERS} variant="line">
            I'm a Game Developer
          </LinkButton>
        </div>
        <div className="home-page__landing_background-video">
          <video autoPlay loop muted poster={poster} playsInline preload="none">
            <source src={'https://cdn.reneverse.io/home-page/video.mp4'} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="home-page__impressions">
        <p>Total Impressions Served</p>
        <Odometer value={counter} format="(.ddd),dd" />
      </div>
      <div className="home-page__airdrop">
        <Link to={Pages.AIRDROP}>
          <ReneLogo />
          <h2>AIRDROP SEASON 2</h2>
        </Link>
      </div>
      <div className="home-page__borderless">
        <h3>REACH PLAYERS LIKE NEVER BEFORE</h3>
        <h2>Immersive In-Game Ads & Product Placement</h2>
        <FeatureAds />
      </div>
      <Animate animateClassName="animate-pilot">
        <div className="home-page__pilot">
          <div className="home-page__pilot_title">
            <h2>THE</h2>
            <h2>Pilot</h2>
          </div>
          <div className="home-page__pilot_content">
            <p>
              ReneVerse’s Borderless Ads will go LIVE for the first time in a limited Pilot Program
              involving an array of Web2 and Web3 games and numerous brands, hailing from various
              industries.
            </p>
            <div className="home-page__pilot_content_stats">
              <div>
                <h4>{homePageData?.['pilot-stats'].brands}</h4>
                <p>Brands</p>
              </div>
              <div>
                <h4>{homePageData?.['pilot-stats'].games}</h4>
                <p>Games</p>
              </div>
              <div>
                <h4>{homePageData?.['pilot-stats'].monthly}</h4>
                <p>Monthly Active Users</p>
              </div>
              <div>
                <h4>{homePageData?.['pilot-stats'].reach}</h4>
                <p>Potential Reach</p>
              </div>
            </div>
          </div>
        </div>
        <div className="home-page__pilot-participants">
          <BannerSlider slides={homePageData?.pilot} />
          <div className="home-page__pilot-participants_buttons">
            <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
              Join Pilot
            </LinkButton>
            {/* <LinkButton to={Pages.BOOK_DEMO} variant="line">
              Book Demo
            </LinkButton> */}
          </div>
        </div>
      </Animate>
      <Animate animateClassName="animate-press">
        <div className="home-page__press">
          <div>
            <h2>IN THE</h2>
            <h2>Press</h2>
          </div>
          <div>
            {homePageData?.articles.map((article) => (
              <ExternalLinkButton key={article.link} href={article.link} variant="text">
                <img src={article.logo} alt="website" />
              </ExternalLinkButton>
            ))}
          </div>
        </div>
      </Animate>
      <Animate animateClassName="animate-partners" threshold={0.3}>
        <div className="home-page__partners">
          <h2>OUR</h2>
          <h2>Partners</h2>
          <div className="home-page__partners_groups">
            <ExternalLinkButton href="https://ancient8.gg/" variant="text">
              <img src={ancient8Logo} alt="ancient8" />
            </ExternalLinkButton>
            {homePageData?.partners && (
              <Slider slides={homePageData.partners} SlideComponent={PartnersCard} />
            )}
          </div>
        </div>
      </Animate>
      {/* <Animate animateClassName="animate-roadmap">
        <RoadMap />
      </Animate> */}
      <div className="home-page__docs">
        <div>
          <h4>Reach Gamers Like Never Before</h4>
          <p>
            Interested in working with ReneVerse?
            <br />
            <br />
            If you want ads in your game, or you have a brand campaign you want to run in-game, book
            a call with us, and we’ll make Borderless Ads work for you.
          </p>
        </div>
        <LinkButton to={Pages.BOOK_DEMO} variant="secondary">
          Book a Call
        </LinkButton>
      </div>
    </div>
  );
};

export default HomePage;
