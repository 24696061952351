import { ExternalLinkButton } from '../../components/button/button';
import { Icons } from '../../components/icon/icon-types';
import { SocialIcon } from '../../components/social-icons/social-icons';
import { AIRDROP_URL, DISCORD_URL, TELEGRAM_URL, TWITTER_URL } from '../../global/constants';

import galxe from './images/galxe.svg';
import step1 from './images/step1.webp';
import step2 from './images/step2.webp';
import step3 from './images/step3.webp';
import coin3 from './images/coin-3.webp';
import coin4 from './images/coin-4.webp';
import coin5 from './images/coin-5.webp';
import number1 from './images/count.png';
import number2 from './images/count2.png';
import number3 from './images/count3.png';

import './airdrop-page.scss';

const steps = [
  {
    img: step1,
    title: 'Step 1',
    description: 'Sign Up on ReneVerse',
  },
  {
    img: step2,
    title: 'Step 2',
    description: 'Follow our Galxe Space',
  },
  {
    img: step3,
    title: 'Step 3',
    description: 'Join Discord & Start Questing',
  },
];

const start = [
  {
    img: coin3,
    number: number1,
    title: 'Join the Galxe Campaign',
    description: (
      <>
        <p>Complete Sign-Up Parent Quest</p>
        <p>Start Airdrop Parent Quest</p>
      </>
    ),
  },
  {
    img: coin4,
    number: number2,
    title: 'Follow our Socials',
    description: (
      <div>
        <SocialIcon iconName={Icons.SocialX} url={TWITTER_URL} />
        <SocialIcon iconName={Icons.SocialTelegram} url={TELEGRAM_URL} />
        <SocialIcon iconName={Icons.SocialDiscord} url={DISCORD_URL} />
      </div>
    ),
  },
  {
    img: coin5,
    number: number3,
    title:
      'Join Discord & complete social tasks, engage in conversation, and participate in events to earn "Engage Points"',
    description: (
      <p>
        Spend these "Engage Points" to earn special Roles in the Discord, worth increasing amounts
        of Galxe Points
      </p>
    ),
  },
];

const AirdropPage = () => {
  return (
    <div className="airdrop-page">
      <section className="airdrop-page__landing">
        <div className="airdrop-page__landing_title">
          <h1>AIRDROP SEASON 2</h1>
        </div>
        <div className="airdrop-page__landing_steps">
          {steps.map((step) => (
            <div>
              <img src={step.img} alt={step.title} />
              <div>
                <h3>{step.title}</h3>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="airdrop-page__description">
        <div className="airdrop-page__description_right">
          <div>
            <p>
              Airdrop Season 2 is here with more quests and more rewards, available now on Galxe!{' '}
            </p>
            <p>
              Engage in contests and giveaways, earn points toward your Airdrop, and join the
              ReneVerse community.{' '}
            </p>
            <ExternalLinkButton variant="secondary" href={AIRDROP_URL} icon={<img src={galxe} alt="galxe" />}>
              Go to Galxe
            </ExternalLinkButton>
          </div>
        </div>
        <div className="airdrop-page__description_left">
          <h3>AIRDROP SEASON 2</h3>
        </div>
      </section>
      <section className="airdrop-page__scrolling">
        <div className="scrolling-text">
          how to start? · how to start? · how to start? · how to start? · how to start? · how to
          start? · how to start? · how to start? · how to start? · how to start? · how to start? ·
          how to start? ·
        </div>
      </section>
      <section className="airdrop-page__start">
        {start.map((step) => (
          <div className="airdrop-page__start_item">
            <img src={step.img} alt="how to start" />
            <div className="airdrop-page__start_item_info">
              <div className="line" />
              <div className="airdrop-page__start_item_info_des">
                <h3>{step.title}</h3>
                {step.description}
              </div>
              <div className="line" />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AirdropPage;
